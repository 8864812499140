import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import Button from '../Button'
import TextField from '../TextField'
import { AuthApi } from '../../api'
import { MENU, SITE_COOKIES } from '../../config'
import { setCookie } from '../../utils/cookies'
import { Toast, Icon } from 'antd-mobile';
import { getErrorMessage } from '../../utils/error'

class AuthLogin extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      form: {
        npp: '',
        password: ''
      },
      type: 'password'
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault()
    let { form, loading } = this.state
    Toast.loading('Loading...', 1000, null, true)
    if(!loading){
      await this.setState({ loading: true })
      const payload = form
      AuthApi.login(payload)
      .then(response => {
        if(response.data.status == 200){
          const data = response.data.data
          setCookie(SITE_COOKIES.EMAIL, data.email, 1)
          setCookie(SITE_COOKIES.NAME, data.fullname, 1)
          setCookie(SITE_COOKIES.AVATAR, data.image, 1)
          setCookie(SITE_COOKIES.OUTLET, data.outlet_name, 1)
          setCookie(SITE_COOKIES.POSITION, data.position_name, 1)
          setCookie(SITE_COOKIES.NPP, data.npp, 1)
          setCookie(SITE_COOKIES.BRANCH, data.branch_name, 1)
          setCookie(SITE_COOKIES.REGION, data.region_name, 1)
          setCookie(SITE_COOKIES.TOKEN, data.token, 1)
          Toast.hide()
          this.props.history.push(MENU.HOME)
        }
      }).catch(e => {
        Toast.hide()
        Toast.fail(getErrorMessage(e), 1.5, null, true)
        this.setState({ loading: false })
      })
    }    
  }

  handleChangeInput = (value, fname) => {
    let { form } = this.state
    form[fname] = value
    this.setState({ form })
  }

  showPassword = () => {
    this.setState({ type: 'text' })
  }

  hidePassword = () => {
    this.setState({ type: 'password' })
  }

  render() {

    let { loading, form:  { npp, password }, type, orientation } = this.state

    return (
      <div className='auth auth-login card card--rounder container-mobile'>
        <img
          className='auth-login__logo'
          src={require('../../assets/images/img-logo.png')}
          alt='logo'
          style={{ margin: '20px auto 40px' }}
        />
        <form onSubmit={ this.handleSubmit }>
          <TextField
            name='npp'
            label='NPP'
            type='text'
            placeholder='Enter your npp'
            required
            value={npp}
            onChange={e => this.handleChangeInput(e.target.value, 'npp')}
          />
          <TextField
            name='password'
            label='Password'
            type={ type }
            placeholder='Enter your password'
            value={password}
            onChange={e => this.handleChangeInput(e.target.value, 'password')}
            padding="0 30px 0 0"
            required
          />
          {
            password.length > 0 &&
            <div style={{ position: 'relative' }}>
              <small style={{ position: 'absolute', right: 5, top: '-40px' }}>
                {/* {
                  type == 'password' 
                  ? <span onClick={ this.showPassword }>Lihat </span> 
                  : <span onClick={ this.hidePassword }>Tutup </span>
                } */}
                {
                  type == 'password' 
                  ? <img src={ require('../../assets/images/eye-off-outline.svg') } onClick={ this.showPassword } />
                  : <img src={ require('../../assets/images/eye-outline.svg') } onClick={ this.hidePassword } />
                }
              </small>
            </div>
          }
          <Link to='/forgot' className='auth-login__forgot'>
            Lupa password?
          </Link>
          <Button type='submit' disabled={(!npp || !password) || loading}>Login</Button>
        </form>
      </div>
    )
  }
}

export default withRouter(AuthLogin)
