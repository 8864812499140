import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Button from '../Button'
import TextField from '../TextField'
import { MENU } from '../../config'
import { AuthApi } from '../../api'

class AuthForgot extends Component {

  state = {
    email: '',
    loading: false
  }

  handleSubmit = async e => {
    e.preventDefault()
    let { email, loading } = this.state
    if(!loading){
      await this.setState({ loading: true })
      AuthApi.forgot({ email: email })
      .then(response => {
        if(response.data.status == 200){
          this.setState({ loading: false })
          this.props.history.push(`${MENU.FORGOT_PASSWORD_CONFIRMED}/${email}`)    
        }
      }).catch(e => {
        alert(e.response.data.message)
        this.setState({ loading: false })
      })
    }
  }

  handleChangeInput = email => {
    this.setState({ email })
  }

  render() {

    let { email } = this.state

    return (
      <div className='auth auth-forgot card card--rounder container-mobile'>
        <p>
          Masukkan email Anda dan kami akan mengirimkan Anda tautan untuk kembali
          ke akun Anda.
        </p>

        <form onSubmit={ this.handleSubmit }>

          <TextField
            name='email'
            label='Email'
            type='email'
            placeholder='Enter your email'
            required
            value={email}
            onChange={ e => this.handleChangeInput(e.target.value) }
          />

          <Button type='submit' disabled={!email} className='auth-forgot__btn'>
            Submit
          </Button>

        </form>
      </div>
    )
  }
}

export default withRouter(AuthForgot)
