import React from 'react'
import ButtonNext from './ButtonNext'

const ButtonCancel = ({ onCancel }) => <div className='comment-input-cancel' onClick={ onCancel }>Batal</div>

const ButtonAttachment = () => (
  <div className='comment-input-attachment'>
    <img src={require('../assets/images/btn-clip.svg')} alt='' />
  </div>
)

const CommentInput = ({ comment, onChange, onSubmit, onCancel }) => (
  <div className='comment-input-overlay'>
    <div className='comment-input container'>
      <h1>Comment</h1>
      <form onSubmit={ onSubmit }>
        <textarea placeholder='Berikan suatu masukan' value={ comment } onChange={ e => onChange(e.target.value) } autoFocus />
      </form>
      <ul className='comment-input__actions'>
        <li>
          <ButtonCancel onCancel={onCancel}/>
        </li>
        <li>
          <ButtonNext handleClick={ onSubmit } />
        </li>
        <li>
          {/* <ButtonAttachment /> */}
          
        </li>
      </ul>
    </div>
  </div>
)

export default CommentInput
