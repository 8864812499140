import React from 'react'
import { withRouter } from 'react-router-dom'
import ButtonBack from './ButtonBack'
import { MENU } from '../config'

const NavbarText = props => (
  <div className='navbar'>
    <div className='navbar__inner navbar-text container'>
      <div onClick={ () => { props.back ? props.history.goBack() : props.history.push(MENU.HOME) } } style={{ zIndex: 1 }}>
        <ButtonBack />
      </div>
      <div className='navbar-text__text'>
        {props.title}
      </div>
    </div>
  </div>
)

export default withRouter(NavbarText)
