import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import PrivateRouter from './private-router'
import { SITE_URL, MENU } from '../config'
import '../assets/scss/main.scss'

import Home from '../pages/Home'
import Login from '../pages/Login'
import Forgot from '../pages/Forgot'
import ForgotConfirmed from '../pages/ForgotConfirmed'
import Reset from '../pages/Reset'
import Materi from '../pages/Materi'
import Quiz from '../pages/Quiz'
import Leaderboard from '../pages/Leaderboard'
import Settings from '../pages/Settings'
import ChangePassword from '../pages/ChangePassword'
import Search from '../pages/Search'
import Notification from '../pages/Notification'
import Filter from '../pages/Filter'

const AppRoute = (
  <Router>
    <div>
      <Switch>
        <Route path={ MENU.FORGOT_PASSWORD } exact component={Forgot} />
        <Route path={ `${MENU.FORGOT_PASSWORD_CONFIRMED}/:email` } exact component={ForgotConfirmed} />
        <Route path={ MENU.RESET } exact component={Reset} />
        <PrivateRouter path={ `${MENU.MATERI}/:id` } exact component={Materi} />
        <PrivateRouter path={ `${MENU.QUIZ}/:stage/:courseId` } exact component={Quiz} />
        <PrivateRouter path={ MENU.FILTER } exact component={Filter} />
        <PrivateRouter path={ MENU.LEADERBOARD } exact component={Leaderboard} />
        <PrivateRouter path={ MENU.SETTINGS } exact component={Settings} />
        <PrivateRouter path={ MENU.CHANGE_PASSWORD } exact component={ChangePassword} />
        <PrivateRouter path={ MENU.SEARCH } exact component={Search} />
        <PrivateRouter path={ MENU.NOTIFICATION } exact component={Notification} />
        <PrivateRouter path={ MENU.HOME } exact component={Home} />
        <Route path={ SITE_URL } exact component={Login} />
      </Switch>
    </div>      
  </Router>
)

export default AppRoute
