export const getErrorMessage = err => {
  let errorMessage = "Terjadi kesalahan, mohon cek koneksi internet anda"  
  if(err instanceof Error){
    let { response } = err
    if(typeof response == 'object'){
      if(response.hasOwnProperty('data')){
        let { code, message } = response.data
        errorMessage = message
      }
    }
  }
  return errorMessage;
}