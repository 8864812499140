import React from 'react'
import AuthForgot from '../components/auth/AuthForgot'

const Login = () => (
  <div className='layout layout--dark'>
    <div className='container fill-height flex align-center'>
      <AuthForgot />
    </div>
  </div>
)

export default Login
