import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import Button from '../Button'
import TextField from '../TextField'

const AuthReset = () => {
  const [submitted, setSubmmitted] = useState(false)
  const [pwd, setPwd] = useState('')
  const [pwd1, setPwd1] = useState('')

  const handleSubmit = e => {
    e.preventDefault()
    setSubmmitted(true)
  }

  return (
    <div className='auth auth-reset card card--rounder container-mobile'>
      <p>
        Masukkan password baru anda.
      </p>

      <form onSubmit={handleSubmit}>
        <TextField
          name='password'
          label='Password Baru'
          type='password'
          placeholder='Enter your password'
          required
          value={pwd}
          onChange={e => setPwd(e.target.value)}
        />

        <TextField
          name='password1'
          label='Konfirmasi Password Baru'
          type='password'
          placeholder='Enter your password'
          required
          value={pwd1}
          onChange={e => setPwd1(e.target.value)}
        />

        <Button type='submit' disabled={!pwd || !pwd1} className='auth-forgot__btn'>
          Simpan
        </Button>
      </form>

      {submitted && <Redirect to='/' push />}
    </div>
  )
}

export default AuthReset
