import React, { Component } from 'react'
import NavbarSearch from '../components/NavbarSearch'
import { Link, withRouter } from 'react-router-dom'
import { CourseApi } from '../api'
import { MENU } from '../config'
import { Toast } from 'antd-mobile'
import Navbar from '../components/Navbar'

const SearchListItem = ({ name }) => (
  <div className='sl-item'>
    <h3>{name}</h3>
  </div>
)

class Filter extends Component {

  state = {
    category: {
      data: [],
      params: {
        limit: 10,
        offset: 0,
        search: '',
      }
    },
    form: {
      search: '',
    },
    loading: true,
  }

  componentDidMount() {
    this.init()
  }

  init = () => {
    this.getCourseCategory()
  }

  getCourseCategory = (offset) => {
    Toast.loading('Loading...', 1000, null, true)
    let { category, form } = this.state
    if(offset !== undefined) category.params.offset = offset
    category.params.search = form.search
    CourseApi.getCategory(category.params)
    .then(response => {
      if(response.data.status === 200){
        let { data } = response.data
        category.data = data
        this.setState({ 
          category,
        }, () => {
          Toast.hide()
        })
      }
    }).catch(err => {
      Toast.hide()
      Toast.fail(err.response.data.message, 1.5, null, true)
    })

  }

  handleChangeInput = e => {
    let { form } = this.state
    form.search = e.target.value
    if(form.search == '') this.getCourseCategory()
    this.setState({ form })
  }

  handleSearchCourse = e => {
    let { form } = this.state
    e.preventDefault()
    this.getCourseCategory()
  }

  render() {

    const { category, form } = this.state

    return (
      <div className='search with-navbar layout'>
        <Navbar title="Filter" />
        <div className='container'>
          { 
          category.data.map((item, i) => (
            <Link to={ `${MENU.HOME}?categoryId=${item.id}` } key={i}>
              <SearchListItem {...item}/>
            </Link>
            ))
          }
          {
            category.data.length == 0 && form.search != '' && <div style={{ textAlign: 'center', marginTop: 25 }}>Materi tidak ditemukan</div>
          }
        </div>
      </div>
    )
  }
}



export default withRouter(Filter)
