import React from 'react'

const TextField = ({
  value,
  onChange,
  label,
  placeholder,
  type,
  name,
  required,
  padding
}) => (
  <div className='text-field'>
    <label>{label}</label>
    <input
      value={value}
      onChange={onChange}
      type={type}
      name={name}
      placeholder={placeholder}
      required={required}
      style={{ padding: padding }}
    />
  </div>
)

export default TextField
