import React, { Component } from 'react'
import NavbarSearch from '../components/NavbarSearch'
import { Link, withRouter } from 'react-router-dom'
import { CourseApi } from '../api'
import { MENU } from '../config'
import { Toast } from 'antd-mobile'

const SearchListItem = ({ created_at, category_name, title }) => (
  <div className='sl-item'>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <h5>{created_at}</h5>
      <h5>{category_name}</h5>
    </div>
    <h2>{title}</h2>
  </div>
)

class Search extends Component {

  state = {
    courses: {
      data: [],
      params: {
        limit: 10,
        offset: 0,
        search: '',
      }
    },
    form: {
      search: '',
    },
    loading: true,
  }

  componentDidMount() {
    this.init()
  }

  init = () => {
    this.getCourses()
  }

  getCourses = (offset) => {
    Toast.loading('Loading...', 1000, null, true)
    let { courses, form } = this.state
    if(offset !== undefined) courses.params.offset = offset
    courses.params.search = form.search
    CourseApi.list(courses.params)
    .then(response => {
      if(response.data.status === 200){
        let { data } = response.data
        courses.data = data
        this.setState({ 
          courses,
        }, () => {
          Toast.hide()
        })
      }
    }).catch(err => {
      Toast.hide()
      Toast.fail(err.response.data.message, 1.5, null, true)
    })

  }

  handleChangeInput = e => {
    let { form } = this.state
    form.search = e.target.value
    if(form.search == '') this.getCourses()
    this.setState({ form })
  }

  handleSearchCourse = e => {
    let { form } = this.state
    e.preventDefault()
    this.getCourses()
  }

  render() {

    const { courses, form } = this.state

    return (
      <div className='search with-navbar layout'>
        <NavbarSearch form={ form } handleChangeInput={ this.handleChangeInput } onSubmit={ this.handleSearchCourse } />
        <div className='container'>
          { 
          courses.data.map((item, i) => (
            <Link to={ `${MENU.MATERI}/${item.id}` }key={i}>
              <SearchListItem {...item}/>
            </Link>
            ))
          }
          {
            courses.data.length == 0 && form.search != '' && <div style={{ textAlign: 'center', marginTop: 25 }}>Materi tidak ditemukan</div>
          }
        </div>
      </div>
    )
  }
}



export default withRouter(Search)
