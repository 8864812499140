import React from 'react'
import { withRouter } from 'react-router-dom'
import ButtonBack from './ButtonBack'
import DateLabel from './DateLabel'

const NavbarDetailMateri = (props) => (
  <div className='navbar'>
    <div className='navbar__inner navbar-detail-materi container'>
      <div onClick={() => props.history.goBack()}>
        <ButtonBack />
      </div>
      <DateLabel date={ props.created_at } isNew={ props.is_new } category={ props.category_name } />
    </div>
  </div>
)

export default withRouter(NavbarDetailMateri)
