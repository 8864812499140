import React from 'react'
import { withRouter } from 'react-router-dom'
import ButtonBack from './ButtonBack'

const NavbarSearch = props => (
  <div className='navbar'>
    <div className='navbar__inner navbar-search container'>
      <div onClick={() => props.history.goBack()} style={{ zIndex: 1 }}>
        <ButtonBack />
      </div>
      <div className='navbar-search__field'>
        <img src={require('../assets/images/ic-search.svg')} alt='' />
        <form onSubmit={ props.onSubmit } style={{ width: '100%' }}>
          <input 
            type='search' 
            placeholder='Apa yang akan kamu cari?' 
            value={ props.form.search } 
            onChange={ props.handleChangeInput } 
            autoFocus 
            style={{ width: '100%' }}
          />
        </form>
      </div>
    </div>
  </div>
)

export default withRouter(NavbarSearch)
