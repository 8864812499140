import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import Button from '../Button'

const AuthForgotConfirmed = (props) => {
  return (
    <div className='auth auth-forgot-confirmed card card--rounder container-mobile'>
      <img src={require('../../assets/images/ic-checked.svg')} alt='' />
      <p>
        Kami mengirimkan email ke alamat { props.match.params.email } dengan link untuk
        mengembalikan akun anda.
      </p>
      <Link to='/'>
        <Button>
          Login
        </Button>
      </Link>
    </div>
  )
}

export default withRouter(AuthForgotConfirmed)
