import axios from 'axios'

export default ({ url, config, defaultParams }) => {
  const endpoint = '/quizzes/quiz'
  return {
    list: () => axios.get(`${url}${endpoint}/list`, config()),
    get: params => axios.get(`${url}${endpoint}/select-by-course/${params.id}`, config()),
    submit: data => axios.post(`${url}/quizzes/question/save/${data.employee_quiz_id}`, data, config()),
    getTermAndCondition: () => axios.get(`${url}/other/content/quizz-term`, config()),
  }
}