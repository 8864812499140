import React from 'react'

const Avatar = ({ src, size = 40 }) => {
  return (
    <div className='avatar' style={{ width: size, height: size }}>
      <img src={src} alt='' />
    </div>
  )
}

export default Avatar
