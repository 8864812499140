import React from 'react'

const ButtonSettings = () => (
  <img
    className='button-hex'
    style={{ cursor: 'pointer' }}
    src={require('../assets/images/btn-settings.svg')}
    alt=''
  />
)

export default ButtonSettings
