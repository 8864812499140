import React from 'react'

const TabBar = ({ items = [], active = 0, onClick }) => (
  <div className='tab-bar'>
    <div className='tab-bar__container'>
      {items.map((item, i) => (
        <div
          className={`tab-bar__item ${
            active === i ? 'tab-bar__item--active' : ''
          }`}
          key={i}
          onClick={() => onClick(i)}
        >
          {item}
        </div>
      ))}
    </div>
  </div>
)

export default TabBar
