import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import NavbarDetailmateri from '../components/NavbarDetailMateri'
import ButtonComment from '../components/ButtonComment'
import CommentInput from '../components/CommentInput'
import CommentLikes from '../components/CommentLikes'
import Comment from '../components/Comment'
import { CourseApi } from '../api'
import moment from 'moment';
import { MENU } from '../config'
import { Toast } from 'antd-mobile'
import { getErrorMessage } from '../utils/error'

class Materi extends Component {

  state = {
    courseId: this.props.match.params.id,
    data: {},
    comment: '',
    comments: [],
    loading: true,
    isError: false,
    errorMessage: '',
    showFormComment: false,
  }

  componentDidMount() {
    this.init()
  }

  init = () => {
    this.getDetailCourse(this.props.match.params.id)
  }

  getDetailCourse = (id) => {
    Toast.loading('Loading...', 1000, null, true)
    CourseApi.detail(id)
    .then(response => {
      if(response.data.status === 200){
        let { data } = response.data
        this.getComments(this.props.match.params.id)
        this.setState({ data }, () => {
          Toast.hide()
        })
      }
    }).catch(err => {
      Toast.hide()
      Toast.fail(getErrorMessage(err), 1.5, null, true)
      let message = 'Terjadi kesalahan memuat data materi'
      this.setState({ loading: false, isError: true, errorMessage: message })
    }) 
  }

  getComments = (id) => {
    CourseApi.commentList(id)
    .then(response => {
      if(response.data.status === 200){
        let { data } = response.data
        this.setState({ 
          comments: data,
          loading: false
        })
      }
    }).catch(err => {
      Toast.fail(getErrorMessage(err), 1.5, null, true)
    }) 
  }

  handleSubmitComment = e => {
    e.preventDefault()
    Toast.loading('Loading...', 1000, null, true)
    const { courseId, comment } = this.state
    if(comment.length > 0){
      let payload = {
        text: comment,
        course_id: courseId,
      }
      CourseApi.addComment(payload)
      .then(response => {
        if(response.data.status === 200){
          let { showFormComment } = this.state
          this.setState({ comment: '', showFormComment: !showFormComment })
          this.getComments(courseId)
          Toast.hide()
        }
      }).catch(err => {
        Toast.hide()
        Toast.fail(getErrorMessage(err), 1.5, null, true)
      }) 
    }
  }

  handleToggleFormComment = () => {
    let { showFormComment } = this.state
    this.setState({ showFormComment: !showFormComment })
  }

  handleChangeComment = value => {
    this.setState({ comment: value })
  }

  handleClickLike = e => {
    const { courseId } = this.state
    Toast.loading('Loading...', 1000, null, true)
    CourseApi.like(courseId)
    .then(response => {
      if(response.data.status === 200){
        Toast.hide()
        this.getDetailCourse(courseId)
      }
    }).catch(err => {
      Toast.hide()
      Toast.fail(err.response.data.message, 1.5, null, true)
    })
  }

  renderContent = content => {

    return content.map(item => {
      if(item.content_type == 'TEXT'){
        return <div
          className='materi__content'
          dangerouslySetInnerHTML={{ __html: item.text }}
        />
      }else if(item.content_type == 'VIDEO'){
        let id = item.text.split('?v=')[1]
        let video = `https://www.youtube.com/embed/${id}`
        return <iframe style={{ width: '100%', height: 'calc(100vh * 3 / 9)', marginBottom: 15 }} src={ video } frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
      }else{
        return <div><img src={ item.image.url } alt="" style={{ width: '100%', height: 'auto', marginBottom: 15 }} /></div>
      }
    })

  }

  render() {

    let { showFormComment, data, comments, comment, loading, isError, courseId, errorMessage } = this.state
    let { title, content, created_at, is_new, category_name } = data

    return (
      <React.Fragment>
        {
          !loading
          ? <div className='materi with-navbar' style={{ paddingBottom: 25 }}>
              <NavbarDetailmateri created_at={ created_at } is_new={ is_new } category_name={ category_name } />
              {
                !isError
                ? <React.Fragment>
                    <div className='container'>
                      <div className='materi-content'>
                        <h1 className='materi__title' style={{ marginBottom: 25 }}>
                          { title }
                        </h1>
                        { this.renderContent(content) }
                      </div>
            
                      {
                        !loading
                        // !data.is_taken
                        ? <Link to={{
                            pathname: `${MENU.QUIZ}/tnc/${courseId}`,
                            state: {
                              courseId,
                              stage: 'tnc'
                            }
                          }}>
                            <div className='materi__btn-start'>
                              <strong>Mulai Ikuti Kuis</strong>
                              <small>Pastikan membaca materi terlebih dahulu</small>
                            </div>
                          </Link>
                        : <br />
                      }
            
                      <CommentLikes commentCount={ comments.length } likesCount={ data.likes } liked={ data.is_liked == 1 } handleToggleFormComment={ this.handleToggleFormComment } handleClickLike={ e => this.handleClickLike(e) } />
            
                    </div>
                    <div className='divider' />
                    <div className='container'>
                      { 
                        comments.length > 0 
                        ? comments.map((item, i) => (
                            <Comment
                              key={i}
                              avatar={ item.image }
                              name={ item.comment_by }
                              branch={ `BNI ${item.branch_name}` }
                              comment={ item.text }
                              date={ moment(item.created_at).fromNow() }
                            />
                          ))
                        : <div style={{ color: 'grey', padding: 20, textAlign: 'center' }}>Belum ada komentar</div>
                      }
                      {/* moment(item.created_at).startOf('hour').fromNow(); */}
                    </div>
                    <div onClick={ this.handleToggleFormComment }>
                      <ButtonComment />
                    </div>
                    { showFormComment && <CommentInput onSubmit={ this.handleSubmitComment } comment={ comment } onChange={ this.handleChangeComment } onCancel={ this.handleToggleFormComment } />}
                  </React.Fragment>
                : <div style={{ margin: '25vh auto', textAlign: 'center' }}>{ errorMessage }</div>
              }
            </div>
          : ''
          // : <div style={{ margin: '25vh auto', textAlign: 'center' }}>Memuat...</div>
        }
      </React.Fragment>
    )
  }
}



export default withRouter(Materi)
