import React from 'react'

const CommentLikes = ({ commentCount = 0, likesCount = 0, liked = false, handleToggleFormComment, handleClickLike }) => (
  <div className='comment-likes'>
    <span onClick={ handleToggleFormComment }>
      {commentCount} Comments
      <img src={require('../assets/images/ic-comment-grey.svg')} alt='' />
    </span>
    <span>
      {likesCount} Likes
      {liked ? (
        <span style={{ margin: 0 }}><img src={require('../assets/images/ic-like-orange.svg')} alt='' /></span>
      ) : (
        <span style={{ margin: 0 }} onClick={ handleClickLike }>
          <img src={require('../assets/images/ic-like-grey.svg')} alt='' />
        </span>
      )}
    </span>
  </div>
)

export default CommentLikes
