import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import NavbarText from '../components/NavbarText'
import TextField from '../components/TextField'
import Button from '../components/Button'
import { UserApi } from '../api'
import { MENU } from '../config'
import { Toast } from 'antd-mobile'

class ChangePassword extends Component {

  state = {
    form: {
      old_password: '',
      new_password: ''
    },
    loading: false,
  }

  handleChangeInput = (value, fname) => {
    let { form } = this.state
    form[fname] = value
    this.setState({ form })
  }

  handleSubmit = e => {
    e.preventDefault();
    Toast.loading('Loading...', 1000, null, true)
    let { form, loading } = this.state
    this.setState({ loading: true })
    let payload = form
    UserApi.updatePassword(payload)
      .then(response => {
        if(response.data.status == 200){
          const data = response.data.data
          Toast.hide()
          Toast.success('Password berhasil diupdate', 1.5, null, true)    
          this.props.history.push(MENU.SETTINGS)
        }
      }).catch(e => {
        this.setState({ loading: false })
        Toast.hide()
        Toast.fail(e.response.data.message, 1.5, null, true)
      })
  }

  render() {

    let { form: { old_password, new_password }, loading } = this.state

    return (
      <div className='change-password with-navbar fill-height'>
        <NavbarText back={true} title='Ganti Password' />

        <div className='container container-mobile'>
          <p>Masukkan password lama anda kemudian masukkan password baru anda.</p>

          <TextField
            name='password'
            label='Password Lama'
            type='password'
            placeholder='Masukkan password lama anda'
            required
            value={ old_password }
            onChange={e => this.handleChangeInput(e.target.value, 'old_password')}
          />

          <TextField
            name='password1'
            label='Password Baru'
            type='password'
            placeholder='Masukkan password baru anda'
            required
            value={ new_password }
            onChange={e => this.handleChangeInput(e.target.value, 'new_password')}
          />
        </div>

        <div className='change-password__submit'>
          <div className='container container-mobile'>
            <Button disabled={ (!old_password || !new_password) || loading } onClick={ this.handleSubmit }>
              Ganti Password
            </Button>
          </div>
        </div>

      </div>
    )
  }
}

export default withRouter(ChangePassword)
