import React from 'react'

const ButtonFilter = ({ isActive }) => (
  <img
    className='button-hex'
    style={{ cursor: 'pointer', marginRight: 10, height: '100%' }}
    src={
      !isActive 
      ? require('../assets/images/filter-inactive-ic.png')
      : require('../assets/images/filter-active-ic.png')
    }
    alt=''
  />
)

export default ButtonFilter
