import axios from 'axios'

export default ({ url, config, defaultParams }) => {
  const endpoint = '/courses'
  return {
    list: params => axios.get(`${url}${endpoint}/list`, { ...config(), params: params } ),
    detail: id => axios.get(`${url}${endpoint}/detail/${id}`, config()),
    addComment: data => axios.post(`${url}${endpoint}/comment/add`, data, config()),
    commentList: id => axios.get(`${url}${endpoint}/comment/list/${id}`, config()),
    deleteComment: data => axios.delete(`${url}${endpoint}/comment/list/delete`, data, config()),
    updateComment: data => axios.put(`${url}${endpoint}/comment/update`, data, config()),
    leaderboardSummary: () => axios.get(`${url}${endpoint}/leaderboard`, config()),
    leaderboardByOutlet: () => axios.get(`${url}${endpoint}/leaderboard/outlet`, config()),
    leaderboardByEmployee: () => axios.get(`${url}${endpoint}/leaderboard/employee`, config()),
    like: id => axios.post(`${url}${endpoint}/like/${id}`, null, config()),
    getCategory: params => axios.get(`${url}${endpoint}/category/list`, config()),
  }
}