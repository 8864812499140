import React from "react";

const SelectField = ({
  value,
  onChange,
  label,
  placeholder,
  type,
  name,
  required,
  options,
  padding,
  readOnly
}) => (
  <div className="text-field">
    <label>{label}</label>
    <select
      value={value}
      onChange={onChange}
      type={type}
      name={name}
      placeholder={placeholder}
      required={required}
      readOnly={readOnly}
      style={{
        padding: padding,
        border: "none",
        width: "100%",
        textTransform: "uppercase",
        fontWeight: "bold"
      }}>
      {options && (
        <>
          <option disabled value="">
            {placeholder ? placeholder : "Select"}
          </option>
          {options.map((v, k) => {
            return (
              <option value={v.value} key={k}>
                {v.label}
              </option>
            );
          })}
        </>
      )}
      {!options && <option>{placeholder ? placeholder : "Select"}</option>}
    </select>
  </div>
);

export default SelectField;
