import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import NavbarText from '../components/NavbarText'
import { NotificationApi } from '../api'
import { MENU } from '../config'

const NotifItem = ({ title, created_at, unread = false }) => (
  <div className={`notif-item ${unread ? 'notif-item--unread' : ''}`}>
    <p>{ title }</p>
    <span>{ created_at }</span>
  </div>
)

class Notification extends Component {

  state = {
    items: [],
    loading: true
  }

  // text:
  //   'Ada materi baru tentang `Budaya Kerja BNI`. Yuk pelajari dan ikutan Quiz!',
  // date: 'Hari ini • 17:00',
  // unread: true

  componentDidMount() {
    NotificationApi.list()
    .then(response => {
      if(response.data.status === 200){
        let { data } = response.data
        this.setState({ 
          items: data,
          loading: false
        })
      }
    }).catch(err => {
      this.setState({ 
        loading: false
      })
      console.log('Terjadi kesalahan memuat data notification')
    })
  }

  render() {

    let { items, loading } = this.state

    return (
      <div className='notification with-navbar'>
        <NavbarText title='Notifikasi' />
        <div className='container' style={{ padding: 0 }}>
          {
            loading
            ? <div style={{ margin: '25vh auto', textAlign: 'center' }}>Memuat notifikasi...</div>
            : items.length > 0
              ? items.map((item, i) => (
                  <Link to={ `${MENU.MATERI}/${item.id}` } key={i}>
                    <NotifItem key={i} {...item} />
                  </Link>
                ))
              : <div style={{ margin: '25vh auto', textAlign: 'center' }}>Tidak ada notifikasi</div>
          }
        </div>
      </div>
    )
  }
}

export default withRouter(Notification)
