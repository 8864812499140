import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import Avatar from '../components/Avatar'
import ButtonSettings from '../components/ButtonSettings'
import ButtonSearch from '../components/ButtonSearch'
import CommentLikes from '../components/CommentLikes'
import DateLabel from '../components/DateLabel'
import IcLeaderboard from '../components/icons/IcLeaderboard'
import IcNotification from '../components/icons/IcNotification'
import { getCookie } from '../utils/cookies'
import { SITE_COOKIES, MENU } from '../config'
import { CourseApi, NotificationApi } from '../api'
import { Toast } from 'antd-mobile'
import ButtonFilter from '../components/ButtonFilter'
import IcNotificationActive from '../components/icons/IcNotificationActive'
import { getErrorMessage } from '../utils/error'

const HomeHello = (props) => {
  const { name, avatar } = props.data
  return (
    <div className='home-hello'>
      <div className='home-hello__avatar'>
        <Avatar src={ avatar } size={56} />
      </div>
      <div className='home-hello__name'>
        <small>Hello,</small>
        <h2>{ name }</h2>
      </div>
      <div className='home-hello__btn'>
        <Link to='/settings'>
          <ButtonSettings />
        </Link>
      </div>
    </div>
  )
}

const HomeCard = ({ icon, title, desc, className }) => {
  return (
    <div className={'home-card ' + className}>
      <div className='home-card__icon'>{icon}</div>
      <h3>{title}</h3>
      <h5>{desc}</h5>
    </div>
  )
}

const HomeCardGroup = ({ leaderboard, notification }) => {

  const getPositionText = position => {
    let ranking = 'th'
    if(position <= 3 || position >= 21){
      let str = position.toString()
      let last = str.substr(str.length - 1)
      switch(last){
        case '0': ranking = ''; break;
        case '1': ranking = 'st'; break;
        case '2': ranking = 'nd'; break;
        case '3': ranking = 'rd'; break;
      }     
    }
    return <span>{ position }<sup>{ ranking }</sup></span>
  }

  return (
    <div className='home-card-group'>
      <Link to='/leaderboard' style={{ flex: '1 1 100%' }}>
        <HomeCard
          className='home-card--leaderboard'
          icon={
            <>
              <IcLeaderboard />
              { getPositionText(leaderboard.position) }
            </>
          }
          title='Leaderboard'
          desc={ leaderboard.data_on || '...' }
        />
      </Link>
      <Link to='/notification' style={{ flex: '1 1 100%' }}>
        {/* <HomeCard
          icon={<IcNotification />}
          title='Notifikasi'
          desc={ notification.total_notif > 0 ? `${notification.total_notif} notifikasi` : 'Tidak ada notifikasi' }
        /> */}
        <div className={'home-card'}>
          <div className='home-card__icon'>
            { notification.total_notif > 0 ?  <IcNotificationActive /> : <IcNotification /> }
          </div>
          <h3>Notifikasi</h3>
          <h5>{ notification.total_notif > 0 ? `${notification.total_notif} notifikasi` : 'Tidak ada notifikasi' }</h5>
        </div>
      </Link>
    </div>
  )
}

const HomeMateri = (props) => {
  return (
    <div id="home-materi" className='home-materi' style={{ alignItems: 'baseline' }}>
      <div className='home-materi__title'>
        <h1>Materi Tersedia</h1>
        <h3>{ props.count } materi</h3>
      </div>
      <div style={{ width: '100%', height: 48, textAlign: 'right' }}>
        <Link to='/filter'>
          <ButtonFilter isActive={ props.isActive } />
        </Link>
        <Link to='/search'>
          <ButtonSearch />
        </Link>
      </div>
    </div>
  )
}

const HomeMateriCard = (props) => {
  const { title, category_name, created_at, likes, is_new, comments, thumbnail } = props.data
  return (
    <div className='home-materi-card'>
      <DateLabel date={ created_at } isNew={ is_new } category={ category_name }/>
      <h1 style={{ height: 'auto' }}>{ title }</h1>
      { thumbnail != '' && <img src={ thumbnail } alt={ title } style={{ width: '100%', marginTop: '-15px', marginBottom: 15, borderRadius: 10 }} /> }
      
      <CommentLikes commentCount={ comments } likesCount={ likes } />
    </div>
  )
}

class Home extends Component {

  state = {
    profile: {},
    notification: {
      total_notif: 0
    },
    leaderboard: {
      data_on: '',
      position: 0
    },
    courses: {
      data: [],
      params: {
        limit: 1000,
        offset: 0
      }
    },
    filtered: false,
    loading: true,
    loadingCourse: true,
  }

  componentDidMount() {
    this.init()
  }

  init = () => {
    let { location: { search }, match: { params } } = this.props
    let categoryId = 0;
    if(params.hasOwnProperty('categoryId')){
      categoryId = params.categoryId
    }else{
      if(search != ''){
        if(search.match('categoryId')){
          let id = search.split('categoryId=')
          categoryId = id[1]
        }
      }
    }
    this.getProfile()
    this.getCourses(categoryId)
    this.getLeaderBoard()
    this.getNotification()

    localStorage.removeItem('quiz-result') // result quiz

  }

  getProfile = () => {
    let profile = {}
    profile['name'] = getCookie(SITE_COOKIES.NAME)
    profile['avatar'] = getCookie(SITE_COOKIES.AVATAR)
    this.setState({ profile })
  }

  getLeaderBoard = () => {
    let { leaderboard } = this.state
    CourseApi.leaderboardSummary()
    .then(response => {
      if(response.data.status === 200){
        let { data } = response.data
        leaderboard = {
          data_on: data.data_on,
          position: data.position
        }
        this.setState({ 
          leaderboard,
        })
      }
    }).catch(err => {
      Toast.fail(err.response.data.message, 1.5, null, true)
    })
  }

  getNotification = () => {
    let { notification } = this.state
    NotificationApi.count()
    .then(response => {
      if(response.data.status === 200){
        let { data } = response.data
        this.setState({ 
          notification: response.data.data,
        })
      }
    }).catch(err => {
      Toast.fail(err.response.data.message, 1.5, null, true)
    })
  }

  getCourses = (categoryId) => {
    let { courses } = this.state
    let params = {}
    if(categoryId > 0) {
      params.category_id = categoryId
      this.setState({ filtered: true })
    }else{      
      this.setState({ filtered: false })
    }
    CourseApi.list(params)
    .then(response => {
      if(response.data.status === 200){
        let { data } = response.data
        courses.data = data
        this.setState({ 
          courses, loadingCourse: false
        })
      }
    }).catch(err => {
      Toast.fail(getErrorMessage(err), 1.5, null, true)
      this.setState({ loadingCourse: false })
    })
  }

  resetFilter = () => {
    var elmnt = document.getElementById("home-materi")
    window.scrollTo(0, 0)
    this.props.history.push(MENU.HOME)
    this.getCourses()
  }

  render() {

    const { profile, courses, leaderboard, notification, loadingCourse, filtered } = this.state

    return (
      <div className='layout layout--dark'>
      <div className='container'>
        <HomeHello data={ profile }/>
        <HomeCardGroup leaderboard={ leaderboard } notification={ notification } />
        <HomeMateri isActive={ filtered } count={ courses.data.length }/>
        { 
          !loadingCourse 
          ? courses.data.length > 0
            ? <React.Fragment>
                {
                  courses.data.map((item, i) => (
                    <Link to={ `${MENU.MATERI}/${item.id}` } key={i}>
                      <HomeMateriCard data={ item } />
                    </Link>
                  ))
                }
                {
                  filtered &&
                  <div style={{ margin: '60px auto 30px', textAlign: 'center', color: 'white' }} onClick={ this.resetFilter }>
                    Hapus Filter
                  </div>
                }
              </React.Fragment>
            : <div style={{ margin: '15vh auto', textAlign: 'center', color: 'white' }}>
                {
                  filtered ?
                  <div onClick={ this.resetFilter }>
                    Hapus Filter
                  </div>
                  : "Tidak ada materi"
                }
              </div>
          : <div style={{ margin: '15vh auto', textAlign: 'center', color: 'white' }}>Memuat...</div>
        }
      </div>
    </div>
    )
  }
}

export default withRouter(Home)
