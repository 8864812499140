import React from 'react'

const Button = ({ className, children, disabled, type, onClick }) => {
  return (
    <button
      className={`button ${disabled ? 'button--disabled' : ''} ${className}`}
      type={type}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default Button
