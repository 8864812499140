import React, { Component, useState } from 'react'
import { Route, Link, withRouter } from 'react-router-dom'
import Button from '../components/Button'
import ButtonNext from '../components/ButtonNext'
import { QuizApi } from '../api'
import { MENU } from '../config'
import { Toast } from 'antd-mobile'

const Rules = ({ courseId, data }) => (
  <div className='quiz-rules card card--rounder' style={{ marginBottom: 15 }}>
    <h1>{ data.title }</h1>
    <div dangerouslySetInnerHTML={{ __html: data.content }} />
    <div className='quiz-rules__action'>
      <Link to={{
          pathname: `${MENU.QUIZ}/start/${courseId}`,
          state: {
            courseId,
            stage: 'start'
          }
        }}>
        {
          data.title && <Button>Setuju</Button>
        }
      </Link>
    </div>

    <Link to={ `${MENU.MATERI}/${courseId}` } className='quiz-rules__close'>
      <img src={require('../assets/images/ic-close.svg')} alt='' />
    </Link>

  </div>
)

const QuizQuest = ({ nth, total, data, question, options = [], timer, onSelect, onSubmit }) => {
  return (
    <div className='quiz-quest fill-height'>
      <div className='card card--rounder'>
        <div className='quiz-quest__paging'>
          Pertanyaan {nth} dari {total}
        </div>
        <div className='quiz-quest__timer'>
          { timer.min }:{ timer.sec }
        </div>
        { 
          data.media != null && data.media != ''
          ? <div style={{ width: '100%', marginTop: 20, marginBottom: 30 }}><img src={ data.media.url } style={{ width: '100%' }} /></div>
          : ''
        }
        <h1 className='quiz-quest__question' style={{ height: 'auto', marginBottom: 50 }}>{question}</h1>
        <ul className='quiz-quest__options'>
          {
            options.map(item => (
              <li
                key={ item.answer_id }
                className={ item.selected ? '--selected' : '' }
                onClick={ e => onSelect(item) }
              >
                { `${item.option}. ${item.answer_text}` }
              </li>
            ))
          }
        </ul>
      </div>

      <div className='quiz-quest__action' onClick={ onSubmit }>
        <div className='quiz-quest__action-btn'>
          <ButtonNext />
        </div>
      </div>
    </div>
  )
}

const QuizFinish = () => {

  const data = JSON.parse(localStorage.getItem('quiz-result'))
  const { result: { score, true_answer, total_question } } = data
  Toast.hide()
  return (
    <div className='quiz-finish'>
      <img src={require('../assets/images/img-quiz-complete.svg')} alt='' />
      <h1>Anda mendapatkan { parseInt(score) } points</h1>
      <h5>
        <span>{ true_answer }</span> pertanyaan terjawab dengan benar dari <span>{ total_question }</span> total
        pertanyaan
      </h5>
      <Link to='/leaderboard'>
        <button className='quiz-finish__btn'>
          <img
            className='quiz-finish__btn-icon'
            src={require('../assets/images/ic-leaderboard-white.svg')}
            alt=''
          />
          Leaderboard
        </button>
      </Link>
      <Link to='/home'>
        <button className='quiz-finish__btn-close'>Keluar</button>
      </Link>
    </div>
  )
}


const intervalTime = 1000

class Quiz extends Component {

  constructor(props) {
    super(props)
  }

  state = {
    courseId: this.props.match.params.courseId,
    data: '',
    stage: '',
    loading: true,
    timer: {},
    intervalCountDown: null,
    isPristine: true,
    finish: false,
    hasSelected: false,
  }

  componentDidMount() {
    this.init()
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.init()        
    }
  }

  init = () => {
    let { courseId } = this.state
    let stage = this.props.match.params.stage
    this.setState({ stage, data: '', loading: true })
    switch(stage) {
      case 'tnc' : this.getTermAndCondition(); break;
      case 'start' : this.getQuiz(courseId); break;
      case 'finish' : break;
    }
  }
  
  getTermAndCondition = () => {
    Toast.loading('Loading...', 1000, null, true)
    QuizApi.getTermAndCondition()
      .then(response => {
        let { data, status } = response.data
        if(status == 200){
          this.setState({ loading: false, data }, () => { Toast.hide() })
        }
      }).catch(err => {
        Toast.hide()
        Toast.fail(err.response.data.message, 1.5, null, true)
      })
  }

  getQuiz = (id) => {
    Toast.loading('Loading...', 1000, null, true)
    this.setState({ loading: true })
    QuizApi.get({ id })
    .then(response => {
      let { data, status } = response.data
      if(status == 200){
        let timer = {
          status: 'start',
          min: data.duration_per_question < 10 ? '0' + data.duration_per_question : data.duration_per_question,
          _min: data.duration_per_question,
          sec: '00',
          _sec: 0,
        }
        data.option = data.option.map(item => {
          item.selected = false
          return item
        })
        this.setState({ 
          loading: false, 
          data, timer,
          intervalCountDown: setInterval(() => this.countDown(), intervalTime)
        }, () => { Toast.hide() })
      }
    }).catch(err => {
      Toast.hide()
      Toast.fail(err.response.data.message, 1.5, null, true)
    })
  }

  countDown = () => {
    let { timer } = this.state
    let { status, _min, min, _sec, sec } = timer
    if(status == 'start'){
      if(_min == 0 && _sec == 0){
        status = 'end'
        this.handleSubmit()
      }
      if(_min > 0){
        if(_sec == 0){
          _min -= 1
          _sec = 59
        }else{
          _sec--
        }
      }else{
        if(_sec > 0) _sec--
      }
    }
    min = _min < 10 ? '0' + _min : _min
    sec = _sec < 10 ? '0' + _sec : _sec
    timer = {
      _min, _sec,
      min, sec,
      status
    }
    this.setState({ timer })
  }

  handleSubmit = () => {
    Toast.loading('Loading...', 1000, null, true)
    this.setState({ loading: true })
    let { data: { question: { employee_question_id }, employee_quiz_id, option, number }, courseId } = this.state
    let answer = option.filter(item => item.selected).map(item => item.answer_id)
    let payload = {
      employee_quiz_id,
      answer,
      employee_question_id,
      number
    }
    QuizApi.submit(payload)
      .then(response => {
        let { data } = response.data
        let { finish } = data
        if(response.status == 200){
          if(!finish){
            let timer = {
              status: 'start',
              min: data.duration_per_question < 10 ? '0' + data.duration_per_question : data.duration_per_question,
              _min: data.duration_per_question,
              sec: '00',
              _sec: 0,
            }
            data.option = data.option.map(item => {
              item.selected = false
              return item
            })
            this.setState({ 
              loading: false, 
              data, timer,
              intervalCountDown: setInterval(() => this.countDown(), intervalTime)
            }, () => { Toast.hide() })
          }else{
            localStorage.setItem('quiz-result', JSON.stringify(data))
            this.setState({ 
              data ,
              intervalCountDown: false,
            }, () => { Toast.hide() })
            this.props.history.push(`${MENU.QUIZ}/finish/${courseId}`)
            // }, () => { Toast.hide(); console.log(this.state.data) })
          }
        }
      }).catch(err => {
        Toast.hide()
        Toast.fail('Terjadi kesalahan', 1.5, null, true)
        // Toast.fail(err.response.data.message, 1.5, null, true)
      })
  }

  handleSelect = item => {
    let { data } = this.state
    item.selected = !item.selected
    data.option.forEach((value, i) => {
      if(value.answer_id == item.answer_id){
        data.option[i] = item
        return 1
      }
    });
    this.setState({ data })
  }

  render() {

    let { loading, courseId, data, stage, timer } = this.state

    return (
      <div className='quiz layout layout--dark'>
        {/* style={{ display: 'flex', alignItems: 'center' }} */}
        <div className='container fill-height'> 

          { stage == 'tnc' && <Rules courseId={ courseId } data={ data } /> }
          { stage == 'finish' && <QuizFinish courseId={ courseId } data={ data } /> }
          {
            stage == 'start' 
            ? data != '' && !loading 
              ? <QuizQuest
                  nth={ data.number }
                  total={ data.total }
                  data={ data }
                  question={ data.question.question_text }
                  options={ data.option }
                  timer={ timer }
                  onSubmit={ this.handleSubmit }
                  onSelect={ e => this.handleSelect(e) }
                />
              : ''
            : ''
          }
        </div>
      </div>
    )
  }
}

export default withRouter(Quiz)
