import React from 'react'
import Avatar from './Avatar'

const VoiceChat = () => (
  <div className='comment-voice'>
    <img src={require('../assets/images/btn-play-orange.svg')} alt='' />
    <div className='comment-voice__bar' />
    <div className='comment-voice__time'>00:38</div>
  </div>
)

/**
 * Comment List Item
 * @param {*} type - text | voice
 */
const Comment = ({ type = 'text', avatar, name, branch, comment, date }) => (
  <div className='comment'>
    <div className='comment__info'>
      <div className='comment__avatar'>
        <Avatar src={avatar} size={40} />
      </div>
      <div className='comment__user'>
        <div className='comment__user-name'>{name}</div>
        <div className='comment__user-branch'>{branch}</div>
      </div>
      <div className='comment__date'>{date}</div>
    </div>
    <div className='comment__content'>
      {type === 'voice' ? <VoiceChat /> : comment}
    </div>
  </div>
)

export default Comment
