import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Avatar from "../components/Avatar";
import NavbarText from "../components/NavbarText";
import TextField from "../components/TextField";
import SelectField from "../components/SelectField";

import { MENU } from "../config";
import { UserApi, BACK_TO_LOGIN } from "../api";
import Button from "../components/Button";
import { setCookie } from "../utils/cookies";
import { SITE_COOKIES } from "../config";
import { Toast } from "antd-mobile";

class Settings extends Component {
  state = {
    source: {},
    data: {
      npp: "",
      fullname: "",
      email: "",
      image: "",
      region_code: "",
      branch_code: "",
      outlet_code: "",
      new_outlet_code: null
    },
    regions: [],
    branches: [],
    outlets: [],
    region_code: "",
    formPristine: true,
    loading: true
  };

  componentDidMount() {
    this.init();
  }

  init = () => {
    this.getProfile();
    this.getRegions();
  };
  getRegions = () => {
    UserApi.regions()
      .then(res => {
        return res.data.data.map(v => {
          return { label: v.acronym + " - " + v.name, value: v.code };
        });
      })
      .then(res => {
        this.setState({ regions: res });
      })
      .catch(() => {
        Toast.hide();
        Toast.fail("Terjadi kesalahan memuat data region", 1.5, null, true);
      });
  };
  getBranch = code => {
    UserApi.branches(code)
      .then(res => {
        return res.data.data.map(v => {
          return { label: v.acronym + " - " + v.name, value: v.code };
        });
      })
      .then(res => {
        this.setState({ branches: res });
      })
      .catch(() => {
        Toast.hide();
        Toast.fail("Terjadi kesalahan memuat data branch", 1.5, null, true);
      });
  };
  getOutlet = code => {
    UserApi.outlets(code)
      .then(res => {
        return res.data.data.map(v => {
          return { label: v.name, value: v.code };
        });
      })
      .then(res => {
        this.setState({ outlets: res });
      })
      .catch(() => {
        Toast.hide();
        Toast.fail("Terjadi kesalahan memuat data outlet", 1.5, null, true);
      });
  };
  getProfile = () => {
    Toast.loading("Loading...", 1000, null, true);
    UserApi.profile()
      .then(response => {
        if (response.data.status === 200) {
          let { data } = response.data;
          this.setState(
            {
              data,
              source: {
                fullname: data.fullname,
                email: data.email,
                npp: data.npp,
                branch: data.branch_name,
                region: data.region_name,
                outlet: data.outlet_name,
                outlet_code: data.outlet_code,
                branch_code: data.branch_code,
                region_code: data.region_code
              }
            },
            () => {
              this.getBranch(this.state.data.region_code);
              this.getOutlet(this.state.data.branch_code);
              Toast.hide();
            }
          );
        }
      })
      .catch(err => {
        Toast.hide();
        Toast.fail("Terjadi kesalahan memuat data profile", 1.5, null, true);
      });
  };

  handleChangeInput = (value, fname) => {
    let { data } = this.state;
    data[fname] = value;
    this.setState({ data, formPristine: false });
  };

  handleSubmit = () => {
    Toast.loading("Loading...", 1000, null, true);
    let {
      data: { fullname, email, outlet_code, branch_code, region_code },
      source
    } = this.state;
    let formData = new FormData();
    formData.append("name", fullname);
    formData.append("email", email);
    if (!outlet_code || !branch_code || !region_code || !fullname || !email) {
      Toast.hide();
      return Toast.fail("Data tidak boleh kosong", 1.5, null, true);
    }
    UserApi.updateProfile(formData)
      .then(response => {
        if (response.data.status === 200) {
          setCookie(SITE_COOKIES.NAME, fullname, 1);
          if (outlet_code !== source.outlet_code) {
            UserApi.updateOutlet({ outlet_code: outlet_code })
              .then(() => {
                Toast.hide();
                Toast.success(
                  "Profil berhasil diupdate, perubahan wilayah menunggu approval dari pusat",
                  1.5,
                  null,
                  true
                );
                this.getProfile();
              })
              .catch(err => {
                Toast.hide();
                Toast.fail(err.response.data.message, 1.5, null, true);
              });
          } else {
            Toast.success("Profil berhasil diupdate", 1.5, null, true);
          }
        }
      })
      .catch(err => {
        Toast.hide();
        Toast.fail(err.response.data.message, 1.5, null, true);
      });
  };

  handleFile = files => {
    Toast.loading("Loading...", 1000, null, true);
    let { data } = this.state;
    let formData = new FormData();
    formData.append("name", data.fullname);
    formData.append("image", files[0]);
    UserApi.updateProfile(formData)
      .then(response => {
        if (response.data.status === 200) {
          setCookie(SITE_COOKIES.NAME, data.fullname, 1);
          setCookie(SITE_COOKIES.AVATAR, response.data.data.image, 1);
          Toast.hide();
          Toast.success("Profil berhasil diupdate", 1.5, null, true);
          this.getProfile();
        }
      })
      .catch(err => {
        Toast.hide();
        Toast.fail(err.response.data.message, 1.5, null, true);
      });
  };

  render() {
    let {
      data: {
        npp,
        fullname,
        email,
        image,
        region_code,
        branch_code,
        outlet_code,
        new_outlet_name,
        new_outlet_code
      },
      // source: { outlet, branch, region },
      formPristine,
      regions,
      outlets,
      branches
    } = this.state;

    return (
      <div className="settings with-navbar">
        <NavbarText title="Pengaturan" />

        <div
          className="container container-mobile"
          style={{ backgroundColor: "white" }}>
          <div
            className="settings__avatar"
            onClick={e => this.inputElement.click()}>
            <Avatar src={image} size={72} />
            <img
              className="settings__avatar-icon"
              src={require("../assets/images/ic-camera-green.svg")}
              alt=""
            />
            <input
              ref={input => (this.inputElement = input)}
              type="file"
              style={{ display: "none" }}
              onChange={e => this.handleFile(e.target.files)}
            />
          </div>

          <div className="settings__form">
            <TextField label="NPP" value={npp} readOnly />
            <TextField
              label="Nama"
              placeholder="Enter your name"
              value={fullname}
              onChange={e => this.handleChangeInput(e.target.value, "fullname")}
            />
            <TextField
              label="Email"
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={e => this.handleChangeInput(e.target.value, "email")}
            />
            <SelectField
              label="Region"
              placeholder="Select Region"
              options={regions}
              value={region_code}
              onChange={e => {
                this.handleChangeInput(e.target.value, "region_code");
                this.handleChangeInput("", "branch_code");
                this.handleChangeInput("", "outlet_code");
                this.setState({ outlets: [] });
                this.getBranch(e.target.value);
              }}
            />
            <SelectField
              label="Branch"
              placeholder="Select Branch"
              options={branches}
              value={branch_code}
              readOnly={true}
              onChange={e => {
                this.handleChangeInput(e.target.value, "branch_code");
                this.handleChangeInput("", "outlet_code");
                this.getOutlet(e.target.value);
              }}
            />
            <>
              <SelectField
                label="Outlet"
                placeholder="Select Outlet"
                options={outlets}
                required={true}
                value={outlet_code}
                onChange={e =>
                  this.handleChangeInput(e.target.value, "outlet_code")
                }
              />
              {new_outlet_code !== null && (
                <span
                  style={{ fontSize: 14, color: "#ab541b", marginBottom: 20 }}>
                  New outlet <strong>{new_outlet_code}</strong>-
                  <strong>{new_outlet_name}</strong>
                  <br />
                  Waiting approval from Admin Pusat
                </span>
              )}
            </>
            <div style={{ position: "relative", marginTop: 5 }}>
              <Link
                to={MENU.CHANGE_PASSWORD}
                className="settings__form__change"
                style={{ position: "static" }}>
                <TextField readOnly label="Password" type="text" />
                <div style={{ position: "absolute", right: 0, top: 10 }}>
                  Change Password
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div className="">
          <div className="container container-mobile">
            <Button disabled={formPristine} onClick={this.handleSubmit}>
              Update Profile
            </Button>
          </div>
        </div>

        <div
          style={{
            margin: "20px auto 30px",
            width: "100%",
            textAlign: "center"
          }}>
          <span onClick={e => BACK_TO_LOGIN()}>Logout</span>
        </div>
      </div>
    );
  }
}

export default withRouter(Settings);
