import React from 'react'
import AuthReset from '../components/auth/AuthReset'

const Login = () => (
  <div className='layout layout--dark'>
    <div className='container fill-height flex align-center'>
      <AuthReset />
    </div>
  </div>
)

export default Login
