import React from 'react'

const DateLabel = ({ date, isNew, category }) => (
  <div className='date-label'>
    <div className='date-label__date'>{date}</div>
    <div className='date-label__date' style={{ marginLeft: 10 }}>{ category }</div>
    {isNew == 1 && <div className='date-label__new' style={{ marginLeft: 5 }}>New</div>}
  </div>
)

export default DateLabel
