import React from 'react'
import { withRouter } from 'react-router-dom'
import ButtonBack from './ButtonBack'

const Navbar = props => (
  <div className='navbar'>
    <div className='navbar-search container' style={{ textAlign: 'center', paddingTop: 20 }}>
      <div onClick={() => props.history.goBack()} style={{ zIndex: 1, position: 'absolute' }}>
        <ButtonBack />
      </div>
      <h3>{ props.title }</h3>
    </div>
  </div>
)

export default withRouter(Navbar)
