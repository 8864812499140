import axios from "axios";

export default ({ url, config, configCustom }) => {
  const endpoint = "/user";
  return {
    detail: () => axios.get(`${url}${endpoint}/detail`, config()),
    profile: () => axios.get(`${url}${endpoint}/profile`, config()),
    // updateProfile: formData => axios.post(`${url}${endpoint}/profile/update`, formData, config({ contentType: 'multipart/form-data' })),
    updateProfile: formData =>
      axios.post(
        `https://master-api.squ-bss.com/v1/users/update`,
        formData,
        configCustom({ contentType: "multipart/form-data" })
      ),
    updateOutlet: data =>
      axios.post(
        "https://master-api.squ-bss.com/v1/users/changeoutlet",
        data,
        configCustom()
      ),
    updatePassword: data =>
      axios.put(`${url}${endpoint}/change-password`, data, config()),
    regions: () => axios.get(`${url}${endpoint}/region`, config()),
    branches: code =>
      axios.get(`${url}${endpoint}/branch?region_code=` + code, config()),
    outlets: code =>
      axios.get(`${url}${endpoint}/outlet?branch_code=` + code, config())
  };
};
