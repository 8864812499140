import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import NavbarText from '../components/NavbarText'
import TabBar from '../components/TabBar'
import { CourseApi } from '../api'

const LbListItem = ({ i, name, score }) => (
  <div className='lb-list-item flex'>
    <div className='lb-list-item__index'>{i}</div>
    <div className='lb-list-item__name'>{name}</div>
    <div className='lb-list-item__points'>{score} pts</div>
  </div>
)

class Leaderboard extends Component {

  state = {
    tab: 0,
    params: {
      limit: 100,
      offset: 0
    },
    outlets: [],
    employee: []
  }

  componentDidMount() {
    this.init()
  }

  init = () => {
    CourseApi.leaderboardByOutlet()
    .then(response => {
      if(response.data.status === 200){
        let { data } = response.data
        this.setState({ 
          outlets: data,
        })
      }
    }).catch(err => {
      console.log('Terjadi kesalahan memuat data leaderboard outlet')
    })

    CourseApi.leaderboardByEmployee()
    .then(response => {
      if(response.data.status === 200){
        let { data } = response.data
        this.setState({ 
          employee: data,
        })
      }
    }).catch(err => {
      console.log('Terjadi kesalahan memuat data leaderboard employee')
    })

  }

  handleChangeTab = i => {
    this.setState({ tab: i })
  }

  render() {

    let { tab, outlets, employee } = this.state

    return (
      <div className='leaderboard with-navbar layout layout--dark'>
        <NavbarText title='Leaderboard' />
        <TabBar items={['Outlet', 'Employee']} active={tab} onClick={ e => this.handleChangeTab(e) } />
        {
          tab === 0 && (
            <div className='leaderboard__list'>
              {
                outlets.length > 0
                ? outlets.map((item, i) => {
                    item.name = item.outlet_name 
                    return <LbListItem key={i} i={i + 1} {...item} />
                  }
                  )
                : <center style={{ marginTop: '20vh', color: 'white' }}>Belum ada data</center>
              }
            </div>
          )
        }
        {
          tab === 1 && (
            <div className='leaderboard__list'>
              {
                employee.length > 0
                ? employee.map((item, i) => {
                    item.name = item.employee_name 
                    return <LbListItem key={i} i={i + 1} {...item} />
                  }
                )
                : <center style={{ marginTop: '20vh', color: 'white' }}>Belum ada data</center>
              }
            </div>
          )
        }
      </div>
    )
  }

}

export default withRouter(Leaderboard)
