import React from 'react'

const IcNotificationActive = () => (
  <div style={{ position: 'relative' }}>
    <img src={require('../../assets/images/ic-notification-green.svg')} alt='' />
    <img src={require('../../assets/images/ic-notif.svg')} style={{ position: 'absolute', right: 0 }} alt='' />
  </div>
)

export default IcNotificationActive
