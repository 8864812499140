import React from 'react'

const ButtonSearch = () => (
  <img
    className='button-hex'
    style={{ cursor: 'pointer' }}
    src={require('../assets/images/btn-search.svg')}
    alt=''
  />
)

export default ButtonSearch
