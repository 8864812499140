import React, { Component } from 'react'
import AuthLogin from '../components/auth/AuthLogin'
import { withRouter } from 'react-router-dom'
import { getCookie } from '../utils/cookies'
import { SITE_COOKIES, MENU } from '../config'

class Login extends Component {

  state = {
    background: '../assets/images/background-portrait.jpg',
    pageLoading: true
  }

  componentDidMount() {
    this.isLogin()
    var self = this;  
    let background = self.getBackground()
    self.setState({ background, pageLoading: false })
    if ('onorientationchange' in window) {
      window.addEventListener("orientationchange", function() {
        // `this` is now pointing to `window`, not the component. So use `self`.
        background = self.getBackground()
        self.setState({ background })
      }, false);
    }
  }

  isLogin = () => {
    if(getCookie(SITE_COOKIES.TOKEN)) this.props.history.push(MENU.HOME)
  }

  getBackground = () => {
    let { innerHeight, innerWidth } = window
    return innerHeight < innerWidth ? '../assets/images/background-landscape.jpg' : '../assets/images/background-portrait.jpg'
    // return window.screen.orientation.type == 'landscape-primary' ? '../assets/images/background-landscape.jpg' : '../assets/images/background-portrait.jpg'
  }

  render() {
    let { background, pageLoading } = this.state
    return (
      !pageLoading && 
      <div 
        className={'layout layout--login'}
        style={{ backgroundImage: `url('${background}')` }}
      >
        <div className='container align-center'>
          <AuthLogin />
        </div>
      </div>
    )
  }
}

export default withRouter(Login)