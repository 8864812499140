import React from 'react'
import AuthForgotConfirmed from '../components/auth/AuthForgotConfirmed'

const Login = () => (
  <div className='layout layout--dark'>
    <div className='container fill-height flex align-center'>
      <AuthForgotConfirmed />
    </div>
  </div>
)

export default Login
